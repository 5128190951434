import { graphql } from 'gatsby';
import React from 'react';
import HeroOrHeading from '../components/HeroOrHeading';
import TextSection from '../blocks/TextSection';
import SEO from '../components/SEO';

export default function DonatePage(props) {
  const { data = {} } = props;
  const { name, hero, content } = data.donate.nodes[0] || {};
  return (
    <>
      <SEO title="Donate" />
      {hero && <HeroOrHeading hero={hero} name={name} />}
      {content && <TextSection content={content} />}
    </>
  );
}

export const query = graphql`
  query DonateQuery {
    # renames query
    donate: allSanityDonatepage {
      nodes {
        name
        hero {
          ... on SanityHeroWithText {
            name
            description
            image {
              asset {
                url
              }
            }
          }
          ... on SanityAccessibleImage {
            image {
              asset {
                url
              }
            }
            alt
          }
        }
        content {
          name
          _rawContent(resolveReferences: { maxDepth: 10 })
          link {
            link
            url
          }
          link2 {
            url
            link
          }
        }
      }
    }
  }
`;
